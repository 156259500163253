import {
    getAllBusiness,
    getAllRestaurantFood,
    addN,
    addP
  } from '@/config/use_cases/negocios';
  
  const store = {
    namespaced: true,
    state: {
        business_array: {},
        business_array_new: {},
        business_object: {},
        food_array: {},
        food_object: {},
    },
    getters: {
        getAllBusinessArray(state){  
            return state.business_array;
        },
        getAllBusinessArrayNew(state){  
            return state.business_array_new;
        },
        getAllBusinessObject(state){
            return state.business_object;
        },
        getAllFoodArray(state){  
            return state.food_array;
        },
        getAllFoodObject(state){
            return state.food_object;
        }
    },
    mutations: {
        updateBusiness(state, info){
            state.business_array[info.id_ciudad] = info.data.array;
            state.business_object[info.id_ciudad] = info.data.object;
            state.business_array_new[info.id_ciudad] = info.data.array_new;
        },
        updateFood(state, info){
            let food_city = state.food_array[info.id_ciudad];
            if(!food_city){
                state.food_array[info.id_ciudad] = {};
                state.food_object[info.id_ciudad] = {};
            }
            state.food_array[info.id_ciudad][info.id_negocio] = info.data.array;
            state.food_object[info.id_ciudad][info.id_negocio] = info.data.object;
        }
    },
    actions: {
        async getAllBusinessByCity({state, commit}){
            let id_ciudad = this._vm.$session.get('id_ciudad');
            let business = state.business_array[id_ciudad];
            if(business && business.length > 0){
                return business;
            }
            let data = await getAllBusiness(id_ciudad);
            await commit('updateBusiness', {data, id_ciudad});
            return data.array;
        },
        async getAllFootByRestaurant({state, commit}, id_restaurant){
            let id_ciudad = this._vm.$session.get('id_ciudad');
            let food_city = state.food_array[id_ciudad];
            if(food_city){
                let food = food_city[id_ciudad];
                if(food && food.length > 0){
                    return food;
                }
            }
            let data = await getAllRestaurantFood(id_ciudad, id_restaurant)
            await commit('updateFood', {id_restaurant, id_ciudad, data});
            return data.array;
        },
        async createBusiness({state}, elements){
            try {
                await addN(elements)
            } catch (error) {
                console.log(error);
            }
        },
        async createProduct({state}, elements){
            try {
                await addP(elements.platillo, elements.id_negocio);
            } catch (error) {
                console.log(error);
            }
        }
    },
  };
  export default store;
  