import {
    db
} from '@/firebase';
import {
    BUSINESS,
    RESTAURANTS,
    FOODS
} from '@/config/data/parameters';
import {
    getDocs,
    collection,
    doc,
    query,
    where,
    setDoc,
    Timestamp
} from "firebase/firestore/lite";
import {
    getCurrentDateSeconds
} from './filters';

let DIA = [
    'Domingo',
    'Lunes',
    'Martes',
    'Miercoles',
    'Jueves',
    'Viernes',
    'Sabado',
];

async function addN(negocio) {
    let n = negocio;
    n['creation_date'] = Timestamp.fromDate(new Date());
    setDoc(doc(db, BUSINESS, 'zP41l5TEH4oytXqokWO2', RESTAURANTS, negocio.id), n);
}

async function addP(platillo, id_negocio) {
    setDoc(doc(db, BUSINESS, 'zP41l5TEH4oytXqokWO2', RESTAURANTS, id_negocio, FOODS, platillo.id), platillo);
}

async function cerateFood() {
    let negocio = 'tequeria-el-perrito';
    // multi_s
    // multi_a
    // only_one
    let ingre_2 = [{
            type: 'multi_s',
            title: 'Personaliza tu pedido',
            id: 'C1',
            config: {
                "minimo": 1,
                "maximo": 2,
                "require": false
            },
            content: [{
                    "option_name": "Sin cebolla",
                    "price": 0,
                    "id": "A0"
                },
                {
                    "option_name": "Sin repollo",
                    "price": 0,
                    "id": "A1"
                },
                {
                    "option_name": "Sin queso",
                    "price": 0,
                    "id": "A3"
                }
            ]
        },
        {
            type: 'only_one',
            title: 'Selecciona tu descuento',
            id: 'C2',
            config: {
                "minimo": 1,
                "maximo": 1,
                "require": true
            },
            content: [{
                    "option_name": "Rebanada de pastel",
                    "price": 5,
                    "id": "A0"
                },
                {
                    "option_name": "Brownie",
                    "price": 0,
                    "id": "A1"
                },
                {
                    "option_name": "Combo sorpresa",
                    "price": 10,
                    "id": "A3"
                }
            ]
        },
        {
            type: 'multi_s',
            title: 'Dale mas sabor a tu pedido',
            id: 'C3',
            config: {
                "minimo": 1,
                "maximo": 2,
                "require": false
            },
            content: [{
                    "option_name": "Doble carne",
                    "price": 15,
                    "id": "A0"
                },
                {
                    "option_name": "Doble queso",
                    "price": 10,
                    "id": "A1"
                },
                {
                    "option_name": "Extra ingrediente secreto",
                    "price": 25,
                    "id": "A3"
                }
            ],
        },
        {
            type: 'multi_a',
            title: 'Refresca tu sed',
            id: 'C4',
            config: {
                "minimo": 0,
                "maximo": 30,
                "require": false
            },
            content: [{
                    "option_name": "Agua de tamarindo 500ml",
                    "price": 15,
                    "id": "A0"
                },
                {
                    "option_name": "Agua de guanabana 500ml",
                    "price": 15,
                    "id": "A1"
                },
                {
                    "option_name": "Agua de tamarindo 2.5L",
                    "price": 60,
                    "id": "A3"
                },
                {
                    "option_name": "Agua de guanabana 2.5L",
                    "price": 60,
                    "id": "A1"
                },
            ],
        }
    ];
    setDoc(doc(db, BUSINESS, 'zP41l5TEH4oytXqokWO2', RESTAURANTS, negocio, FOODS, 'gytfv8dg84ad6g666fg50'), {
        nombre: 'Mega taquito de pastor',
        descripcion: 'Mega taquito de pastor con salsa extra picante para los meros meros',
        imagen: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR8QceJTI2x92ZuXUVH7yAp9c6ywyf3GQUAAQ&usqp=CAU',
        precio: 50.00,
        ingredients: ingre_2,
    });
    setDoc(doc(db, BUSINESS, 'zP41l5TEH4oytXqokWO2', RESTAURANTS, negocio, FOODS, 'gytfv8dg84ad6g666fg51'), {
        nombre: 'Taquito salvaje',
        descripcion: 'Un taco indomable con la valentia de un guerrero azteca.',
        imagen: 'https://media-cdn.tripadvisor.com/media/photo-s/1b/4e/e5/26/taco-estilo-baja-tempurado.jpg',
        precio: 15.00,
        ingredients: ingre_2
    });
    setDoc(doc(db, BUSINESS, 'zP41l5TEH4oytXqokWO2', RESTAURANTS, negocio, FOODS, 'gytfv8dg84ad6g666fg52'), {
        nombre: 'Mega tranca triple doble jumbo',
        descripcion: 'El que no conoce a dios, a cualquier santo le resa, megra tranca de 3 kg con mega choriso argentino, pierna de unicorno y cola de T-Rex.',
        imagen: 'https://www.vvsupremo.com/wp-content/uploads/2018/06/Mega-Torta-900x570.jpg',
        precio: 190.00,
        ingredients: ingre_2
    });
    setDoc(doc(db, BUSINESS, 'zP41l5TEH4oytXqokWO2', RESTAURANTS, negocio, FOODS, 'gytfv8dg84ad6g666fg53'), {
        nombre: 'Gringa divina',
        descripcion: 'Termina con tu hambre por 7 días como si fuera un milagro con el poder saciante de esta suculenta gringa con carne misteriosamente exquisita',
        imagen: 'https://revistaprimeraluz.files.wordpress.com/2018/02/fe-y-razon.png',
        precio: 80,
        ingredients: ingre_2
    });
    setDoc(doc(db, BUSINESS, 'zP41l5TEH4oytXqokWO2', RESTAURANTS, negocio, FOODS, 'gytfv8dg84ad6g666fg54'), {
        nombre: 'Gringa diabolica',
        descripcion: 'Termina con tu hambre por 30 días',
        imagen: 'https://cloudfront-us-east-1.images.arcpublishing.com/metroworldnews/E3ECCVPWVFCOPNNLFX4TJPKVWM.jpg',
        precio: 800,
        ingredients: ingre_2
    });
}

async function getAllRestaurantFood(id_ciudad, id_negocio) {
    let result = {
        array: [],
        object: {}
    }
    try {
        let Foods = await getDocs(collection(db, BUSINESS, id_ciudad, RESTAURANTS, id_negocio, FOODS));
        Foods.forEach(doc => {
            let info = doc.data();
            result.array.push(info);
            result.object[info.id] = info;
        });
    } catch (error) {
        console.log(error);
    }
    return result;
    // try {
    //     const provider = new GoogleAuthProvider();
    //     let result = await signInWithPopup(auth, provider);
    //     console.log(result.uid)
    //     GoogleAuthProvider.credentialFromResult(result);
    //     const user = result.user;
    //     const docRef = doc(db, "usuarios", user.uid);
    //     let data = await getDoc(docRef);

    //     setDoc(doc(db, 'usuarios', user.uid), {
    //         name: user.displayName,
    //         email: user.email
    //     });
    //     console.log(data.data());
    //     alert(`Hola ${user.displayName}`)
    // } catch (error) {
    //     console.log("No open");
    // }
}

async function getAllBusiness(id_ciudad, activo=true) {
    let result = {
        array: [],
        array_new: [],
        object: {}
    }
    try {
        let current_date_number = new Date().toLocaleTimeString('en-US', { hour12: false }).replace(':', '');
        current_date_number = parseInt(current_date_number);
        let current_day = DIA[new Date().getDay()];
        let q = activo ? query(collection(db, BUSINESS, id_ciudad, RESTAURANTS), where("activo", "==", true)) : query(collection(db, BUSINESS, id_ciudad, RESTAURANTS));
        let business = await getDocs(q);
        let current_date = getCurrentDateSeconds();
        business.forEach(doc => {
            let info = doc.data();
            if (info.horario) {
                let horario = info.horario[current_day];
                if(!horario || horario.length == 0) {
                    info['status'] = 'Cerrado';
                    info['color_status'] = '#FF5252';
                } else {
                if (horario.length == 1) {
                    if (getOpen(horario[0]) < current_date_number && getClosed(horario[0]) > current_date_number) {
                        info['status'] = 'Abierto';
                        info['color_status'] = '#4CAF50';
                    } else {
                        info['status'] = 'Cerrado';
                        info['color_status'] = '#FF5252';
                    }
                } else {
                    for (let i = 0; i < horario.length; i++) {
                        if (getOpen(horario[i]) < current_date_number && getClosed(horario[i]) > current_date_number) {
                            info['status'] = 'Abierto';
                            info['color_status'] = '#4CAF50';
                            i = horario.length + 1;
                        } else {
                            info['status'] = 'Cerrado';
                            info['color_status'] = '#FF5252';
                        }
                    }
                }
            }
            } else {
                info['status'] = 'Sin Verificar';
                info['color_status'] = '#2196F3';
            }
            result.array.push(info);
            result.object[info.id] = info;
            let diferencia = current_date - info.creation_date.seconds;
            if (diferencia < 604800) {
                result.array_new.push(info);
            }
        });
    } catch (error) {
        console.log(error);
    }
    return result;
}

function getClosed(horario) {
    return parseInt(horario.closed.replace(':', ''));
}
function getOpen(horario) {
    return parseInt(horario.open.replace(':', ''));
}

export {
    getAllBusiness,
    cerateFood,
    getAllRestaurantFood,
    addN,
    addP
}