import {
  getPublicityTop,
  getPublicityMobil,
  getPublicityDesktop,
  getDefault,
} from '@/config/use_cases/home';
import {
  Login_Google,
  Logout,
  get_current_user
} from '@/config/use_cases/login';
import {
  getPaises,
  getEstados,
  getMunicipios,
  getCiudades,
} from '@/config/use_cases/ubicacion';

const store = {
  namespaced: true,
  state: {
    top_publicity: {},
    mobil_publicity: {},
    desktop_publicity: {},
    city_name: '',
    user_name: '',
    paises: [],
    estados: {},
    municipios: {},
    ciudades: {},
  },
  getters: {
    getPublicityTopCurrent(state) {
      return state.top_publicity;
    },
    getPublicityMobilCurrent(state) {
      return state.mobil_publicity;
    },
    getPublicityDesktopCurrent(state) {
      return state.desktop_publicity;
    },
    getCityName(state) {
      let name = state.user_name;
      return name && name != '' ? state.city_name : '';
    },
    getUserName(state) {
      let name = state.user_name;
      return name && name != '' ? name : state.city_name;
    },
    getUserIsLogged(state){
      let name = state.user_name;
      return name && name != '' ? true:false;
    }
  },
  mutations: {
    updatePublicityTop(state, info) {
      state.top_publicity[info.id_ciudad] = info.data;
    },
    updatePublicityMobil(state, info) {
      state.mobil_publicity[info.id_ciudad] = info.data;
    },
    updatePublicityDesktop(state, info) {
      state.desktop_publicity[info.id_ciudad] = info.data;
    },
    updateCityName(state, city_name) {
      state.city_name = city_name;
    },
    updateUserName(state, user_name) {
      state.user_name = user_name;
    },
    updatePaises(state, data) {
      state.paises = data;
    },
    updateEstados(state, info) {
      state.estados[info.id_pais] = info.data;
    },
    updateMunicipios(state, info) {
      state.municipios[info.id_estado] = info.data;
    },
    updateCiudades(state, info) {
      state.ciudades[info.id_municipio] = info.data;
    },
  },
  actions: {
    async getPublicityTopAction({
      commit,
      state
    }) {
      let id_ciudad = this._vm.$session.get('id_ciudad');
      let in_data = state.top_publicity[id_ciudad];
      if (in_data && in_data.length > 0) {
        return in_data;
      }
      let data = await getPublicityTop(id_ciudad);
      await commit('updatePublicityTop', {
        id_ciudad,
        data,
      });
    },
    async getPublicityMobilAction({
      commit,
      state
    }) {
      let id_ciudad = this._vm.$session.get('id_ciudad');
      let in_data = state.mobil_publicity[id_ciudad];
      if (in_data && in_data.length > 0) {
        return in_data;
      }
      let data = await getPublicityMobil(id_ciudad);
      await commit('updatePublicityMobil', {
        id_ciudad,
        data,
      });
    },
    async getPublicityDesktopAction({
      commit,
      state
    }) {
      let id_ciudad = this._vm.$session.get('id_ciudad');
      let in_data = state.desktop_publicity[id_ciudad];
      if (in_data && in_data.length > 0) {
        return in_data;
      }
      let data = await getPublicityDesktop(id_ciudad);
      await commit('updatePublicityDesktop', {
        id_ciudad,
        data,
      });
      return data;
    },
    async getDefaultAction() {
      let data = await getDefault();
      this._vm.$session.clear();
      this._vm.$session.start();
      this._vm.$session.set('id_pais', data.id_pais);
      this._vm.$session.set('id_estado', data.id_estado);
      this._vm.$session.set('id_municipio', data.id_municipio);
      this._vm.$session.set('id_ciudad', data.id_ciudad);
      this._vm.$session.set('city_name', data.name_ciudad);
    },
    async login({
      commit
    }) {
      let data = await Login_Google();
      if (data) {
        this._vm.$session.set('user_name', data.displayName);
        await commit('updateUserName', data.displayName);
      }
      return data;
    },
    async logout_session({
      commit
    }) {
      let result = Logout();
      if (result) {
        this._vm.$session.set('user_name', '');
        await commit('updateUserName', '');
      }
    },
    async getAllCountry({
      commit,
      state
    }) {
      let paises = state.paises;
      if (paises && paises.length > 0) {
        return paises;
      }
      let data = getPaises();
      await commit('updatePaises', data);
      return data;
    },
    async getAllEstadosByCountry({
      commit,
      state
    }) {
      let id_pais = this._vm.$session.get('id_pais');
      let estados = state.estados[id_pais];
      if (estados && estados.length > 0) {
        return estados;
      }
      let data = await getEstados(id_pais);
      await commit('updateEstados', {
        data,
        id_pais
      });
      return data;
    },
    async getAllMunicipiosByEstado({
      commit,
      state
    }) {
      let id_estado = this._vm.$session.get('id_estado');
      let municipios = state.municipios[id_estado];
      if (municipios && municipios.length > 0) {
        return municipios;
      }
      let id_pais = this._vm.$session.get('id_pais');
      let data = await getMunicipios(id_pais, id_estado);
      await commit('updateMunicipios', {
        data,
        id_estado
      });
      return data;
    },
    async getAllCitiesByMunicipio({
      commit,
      state
    }) {
      let id_municipio = this._vm.$session.get('id_municipio');
      let ciudades = state.ciudades[id_municipio];
      if (ciudades && ciudades.length > 0) {
        return ciudades;
      }
      let id_estado = this._vm.$session.get('id_estado');
      let id_pais = this._vm.$session.get('id_pais');
      let data = await getCiudades(id_pais, id_estado, id_municipio);
      await commit('updateCiudades', {
        data,
        id_municipio
      });
      return data;
    },
    async getCurrentUser(){
      let user = await get_current_user();
      console.log(user)
      return user;
    }
  },
};
export default store;