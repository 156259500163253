import Vue from 'vue'
import VueRouter from 'vue-router'
const DefaultContainer = () => import("@/components/DefaultContainer.vue");
const Comercio = () => import("@/views/Dashboard/Comercio.vue");
const Home = () => import("@/views/Home.vue");
const Car = () => import("@/views/Dashboard/Car.vue");
const Pedido = () => import("@/views/Dashboard/PedidoEspecifico.vue");
const Menu = () => import("@/views/Dashboard/Menu.vue");
const Platillo = () => import("@/views/Dashboard/Platillo.vue");
const About = () => import("@/views/About.vue");
// import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    redirect: "/home",
    name: "start",
    component: DefaultContainer,
    children: [
      {
      path: '/',
      name: 'Home',
      component: Home
    },
    {
      path: '/carrito',
      name: 'carrito',
      component: Car
    },
    {
      path: '/platillo',
      name: 'platillo',
      component: Platillo
    },
    {
      path: '/comercio',
      name: 'comercio',
      component: Comercio
    },
    {
      path: '/pedido',
      name: 'pedido',
      component: Pedido,
    },
    {
      path: '/about',
      name: 'About',
      component: About
    },
    {
      path: '/:contenido',
      name: 'contenido',
      component: Menu
    },
  ],
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

export default router
