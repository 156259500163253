import {
    CIUDAD,
    UBICACION,
    PAIS,
    ESTADO,
    MUNICIPIO,
    CONFIG
} from '@/config/data/parameters';
import {
    db
} from '@/firebase';
import {
    getDocs,
    collection,
} from "firebase/firestore/lite";

async function getPaises(){
    try {
        let paises = await getDocs(collection(db, CONFIG, UBICACION, PAIS));
        let result = [];
        paises.forEach(doc => {
            result.push(doc.data())
        });
        return result;
    } catch (error) {
        console.log("Error: ",error);
        return []
    }
}

async function getEstados(id_pais){
    try {
        let estados = await getDocs(collection(db, CONFIG, UBICACION, PAIS, id_pais, ESTADO));
        let result = [];
        estados.forEach(doc => {
            result.push(doc.data())
        });
        return result;
    } catch (error) {
        console.log("Error: ",error);
        return []
    }
}

async function getMunicipios(id_pais, id_estado){
    try {
        let municipios = await getDocs(collection(db, CONFIG, UBICACION, PAIS, id_pais, ESTADO, id_estado, MUNICIPIO));
        let result = [];
        municipios.forEach(doc => {
            result.push(doc.data())
        });
        return result;
    } catch (error) {
        console.log("Error: ",error);
        return []
    }
}

async function getCiudades(id_pais, id_estado, id_municipio){
    try {
        let ciudades = await getDocs(collection(db, CONFIG, UBICACION, PAIS, id_pais, ESTADO, id_estado, MUNICIPIO, id_municipio, CIUDAD));
        let result = [];
        ciudades.forEach(doc => {
            result.push(doc.data())
        });
        return result;
    } catch (error) {
        console.log("Error: ",error);
        return []
    }
}


export {
    getPaises,
    getEstados,
    getMunicipios,
    getCiudades
} 