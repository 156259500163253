import Vue from 'vue'
import Vuex from 'vuex'
import home from './home';
import business from './business';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loading: false
  },
  mutations: {
    onLoading(state){
      state.loading = true;
    },
    offLoading(state){
      state.loading = false;
    }
  },
  getters: {
    isLoading(state){
      return state.loading;
    }
  },
  actions: {
  },
  modules: {
    home,
    business
  }
})
