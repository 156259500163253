const USERS = "users";
const BUSINESS = "business";
const ESTADO = "estado";
const MUNICIPIO = "municipio";
const NEGOCIO = "negocio";
const CIUDAD = "ciudad";
const UBICACION = "ubicacion";
const PAIS = "pais";
const MEXICO = "rcNwBPPjtkbhL7sSuqZJ";
const CONFIG = "config";
const TOP_PUBLICITY = "publicity_top";
const PUBLICITY_MOBIL = "publicity_mobil";
const PUBLICITY_DESKTOP = "publicity_desktop";
const DEFAULT = "default";
const RESTAURANTS = "restaurants";
const FOODS = 'foods';

export {
    CIUDAD,
    UBICACION,
    PAIS,
    MEXICO,
    USERS,
    BUSINESS,
    ESTADO,
    MUNICIPIO,
    NEGOCIO,
    CONFIG,
    TOP_PUBLICITY,
    PUBLICITY_MOBIL,
    PUBLICITY_DESKTOP,
    DEFAULT,
    RESTAURANTS,
    FOODS
};