export const getCurrentTime = () => {

}

export const getCurrentDateSeconds = () => {
    let date_timestamp = new Date();
    return Math.floor(date_timestamp.getTime()/1000);
}

export const getCurrentMilitaryTime = () => {

}