<template>
  <v-app id="inspire">
    <router-view v-if="enable_text === 4 && !loading"></router-view>
    <v-container
      v-else
      fluid
      class="fill-height"
      style="background-color: rgba(255, 255, 255, 0.5)"
    >
      <v-row justify="center" align="center">
        <v-col lg="2" md="2" sm="5" cols="6">
          <v-img src="@/assets/logo.svg"></v-img>
        </v-col>
        <v-col cols="12" class="text-center pa-0 ma-0">
          <v-progress-circular
            :size="50"
            :width="3"
            color="info"
            rotate="0"
            indeterminate
          >
            {{ loading ? '' : `${enable_text * 25}%` }}
          </v-progress-circular>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex';
export default {
  name: 'App',
  metaInfo: {
    title: 'Bienvenido a muyhambriento.com',
    meta: [
      {
        name: 'description',
        content: 'Encuentra los mejores platillos en tu ciudad solo en muy hambriento.com'
      },
       {
        name: 'keywords',
        content: 'muy hambriento, Muy Hambriento, Muy Hambriento, Comida, Domicilio, domicilio, comida'
       }
    ]
  },
  data: () => ({
    //
    texto: 'cargando delicias...',
    enable_text: 0,
  }),
  methods: {
    ...mapActions('home', [
      'getPublicityTopAction',
      'getPublicityMobilAction',
      'getPublicityDesktopAction',
      'getDefaultAction',
    ]),
    ...mapMutations('home', ['updateCityName', 'updateUserName']),
    ...mapActions('business', ['getAllBusinessByCity']),
    updateCity() {
      let city_name = this.$session.get('city_name');
      this.updateCityName(city_name);
    },
    async initialValidation() {
      this.enable_text = 0;
      // await this.getDefaultAction();
      if (!this.$session.exists() || !this.$session.get('id_ciudad')) {
        await this.getDefaultAction();
      }
      this.loadData();
      this.updateCity();
      this.loadTop();
      this.loadMobil();
      this.loadDesktop();
      this.loadBusiness();
    },
    async loadTop() {
      await this.getPublicityTopAction();
      this.enable_text++;
    },
    async loadMobil() {
      await this.getPublicityMobilAction();
      this.enable_text++;
    },
    async loadDesktop() {
      await this.getPublicityDesktopAction();
      this.enable_text++;
    },
    async loadBusiness() {
      await this.getAllBusinessByCity();
      this.enable_text++;
    },
    async loadData() {
      let user_name = this.$session.get('user_name');
      if (user_name) {
        await this.updateUserName(user_name);
      }
    },
  },
  computed: {
    ...mapGetters(['isLoading']),
    loading() {
      return this.isLoading;
    },
  },
  created() {
    this.initialValidation();
  },
};
</script>
<style>
.two_lines {
  display: -webkit-box;
  max-width: 400px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
/* p {
  width: 0;
  overflow: hidden;
  white-space: nowrap;
  font-size: 1rem;
  margin: 0 auto;
  font-family: "Courier New";
  border-right: 0.15em solid black;
  animation: typing 4s steps(38) 1s 1 normal both, blink 1s steps(1) infinite;
}
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
@keyframes blink {
  50% {
    border-color: transparent;
  }
} */
</style>
