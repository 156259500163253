import { collection, doc, getDoc, getDocs } from 'firebase/firestore/lite';
import { BUSINESS, CONFIG, DEFAULT, PUBLICITY_DESKTOP, PUBLICITY_MOBIL, TOP_PUBLICITY } from '../data/parameters';
import {
    db
} from '@/firebase';

async function getPublicityTop(id_city) {
    try {
        let publicity = await getDocs(collection(db, BUSINESS, id_city, TOP_PUBLICITY));
        let result = []
        publicity.forEach(doc => {
            result.push(doc.data());
        });
        return result;
    } catch (error) {
        console.log('Error:', error);
        return []
    }
}

async function getPublicityMobil(id_city) {
    try {
        let publicity = await getDocs(collection(db, BUSINESS, id_city, PUBLICITY_MOBIL));
        let result = []
        publicity.forEach(doc => {
            result.push(doc.data());
        });
        return result;
    } catch (error) {
        console.log(error);
        return []
    }
}

async function getPublicityDesktop(id_city) {
    try {
        let publicity = await getDocs(collection(db, BUSINESS, id_city, PUBLICITY_DESKTOP));
        let result = []
        publicity.forEach(doc => {
            result.push(doc.data());
        });
        return result;
    } catch (error) {
        console.log(error);
        return []
    }
}

async function getDefault() {
    try {
        let default_data = await getDoc(doc(db, CONFIG, DEFAULT));
        return default_data.data();
    } catch (error) {
        console.log(error);
        return []
    }
}

export {
    getPublicityTop,
    getPublicityMobil,
    getPublicityDesktop,
    getDefault
}